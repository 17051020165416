<template>
  <div class="row price_right py-4 no-gutters pl-4 pr-3">
    <div class="col-12 col-sm-2 p_img">
      <img :src="baseUrl + item?.photo" alt="photo" class="product-image" />
    </div>
    <!-- // item && 'http://127.0.0.1:8000' + -->
    <div
      class="col-12 col-sm-7 pb-10 pb-sm-0"
      :class="{ 'px-3': $vuetify.breakpoint.mdAndUp }"
    >
      <!-- <p>{{ this.activeLang }}</p> -->
      <p class="p_right_t">{{ item?.name[this.activeLang.language] }}</p>
      <p class="p_r_sum">
        <!-- ${{ getPrice }} -->
        <span class="p_r_sum_t"> MOQ(100) </span>
      </p>
      <p class="about_p" ref="descriptionRef"></p>
    </div>
    <div
      class="col-12 col-sm-3 btn_wholesale d-flex flex-column align-center px-1"
    >
      <div class="o_wholesale mt-0">
        <p class="wholesale">Order Wholesale</p>
        <span class="customisation">width customisation MOQ 100</span>
      </div>
      <div class="o_by_shop">
        <p class="by_shop">By shop</p>
        <span class="for_order">Для заказов > 100</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductListItem',
  props: ['item'],
  data() {
    return {
      baseUrl: '',
      activeLang: this.$store.state.language,
    }
  },
  computed: {
    getPrice() {
      let price = 0
      if (this.item) {
        if (this.item?.prices?.length) {
          price = this.item.prices[0].price
        } else if (
          this.item?.soles?.length &&
          this.item.soles[0].prices &&
          this.item?.soles[0].prices?.length
        ) {
          price = this.item.soles[0].prices[0].price
        } else {
          price = this.item.soles[0].price
        }
      }
      return price
    },
  },
  methods: {},
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
    const externalHtml = this.item.description[this.activeLang.language]

    // Вставляем HTML-код в контейнер
    this.$refs.descriptionRef.innerHTML = externalHtml.substring(0, 400)

    // Уменьшаем размер шрифта тега h2
    const h2Element = this.$refs.descriptionRef.querySelector('h2')
    if (h2Element) {
      h2Element.style.fontSize = '18px' // Например, устанавливаем размер 16px
    }
  },
}
</script>

<style scoped lang="scss">
$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;
@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

h2 {
  font-size: 12px !important;
}
.p_img {
  width: 120px;
  img {
    width: 100%;
    object-fit: contain;
  }
}

.price_block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;

  .price_block_left {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    .title_price {
      @include ftz(1.5rem, 600, #31343c);
      text-transform: capitalize;
      letter-spacing: 1px;

      @media (max-width: 540px) {
        @include ftz(1rem, 600, #31343c);
      }
    }
  }

  .price_gr {
    @include ftz(0.875rem, 500, #666666);
    text-transform: capitalize;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  button:focus:not(:focus-visible) {
    background: #ffffff;
    @include ftz(0.875rem, 500, #666666);
    border: 1px solid #ffffff;
  }
}

.n_close {
  display: none;
}

.range_price {
  .v-slider__thumb {
    border-radius: unset;
    width: 3px;
    left: -1.5px;
    &:before {
      content: none;
    }
  }
  .range_p {
    height: 110px;
    background: #ffffff;
    border-radius: 1px;

    .range_t {
      padding-top: 24px;
      padding-left: 24px;

      a {
        @include ftz(0.875rem, 700, #666666);
        text-transform: capitalize;
      }
    }

    .range_line {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      overflow: hidden;
      margin-right: 1.5rem;

      .line_group_top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 0.5rem;
        width: 100%;
        .r_line {
          background: #84c897;
          width: 40%;
          height: 3px;
          border-radius: 1px;
          margin-left: 1rem !important;
          margin-top: 1rem;
        }
        .r_line_max {
          width: 60%;
          height: 3px;
          background: #e8e8e8;
          border-radius: 1px;
        }
      }
      .line_group_bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        width: 45%;
        margin-left: 1.3rem;
        position: relative;
        .group_bottom_left {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-content: flex-start;
          align-items: center;
          .line_left {
            background: #84c897;
            width: 10px;
            height: 3px;
            border-radius: 1px;
            transform: rotate(90deg);
            position: absolute;
            top: -3px;
          }
        }

        .group_bottom_right {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-content: flex-start;
          align-items: center;
          .line_right {
            background: #84c897;
            width: 10px;
            height: 3px;
            border-radius: 1px;
            transform: rotate(90deg);
            position: absolute;
            top: -3px;
          }
        }
      }
    }
  }
  .category_item_main {
    width: 100%;
    padding: 0;
  }

  .price_group {
    background: #ffffff;
    margin: 10px 0;
    padding-left: 24px;
    padding-bottom: 5px;
    .p_g_ttl {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-right: 1.5rem;

      .r_title {
        padding-top: 7px;
        @include ftz(0.875rem, 700, #666666);
        text-transform: capitalize;
      }

      .b_vector {
        display: inline-block;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        font-size: 9px;
      }
    }

    .price_t {
      text-transform: uppercase;
    }

    .g-item {
      display: flex;
      align-items: center;
      .form-check-input {
        cursor: pointer;
        position: relative;
      }
      .form-check-input:checked {
        background-color: $green_c;
        border-color: $green_c;
      }
      @include ftz(0.75rem, 500, #666666);
      text-transform: capitalize;
    }
  }
}

.right_b {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: fit-content;
  @media (max-width: 896px) {
    width: 100%;
  }

  .price_right {
    background: #ffffff;
    border-radius: 2px;
    margin-bottom: 0.5rem;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    @media (max-width: 541px) {
      overflow: scroll;
    }
    @media (max-width: 481px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .p_img {
      img {
        width: 100%;
      }
    }

    .p_right_t {
      @include ftz(1rem, 500, #423f3d);
      text-transform: capitalize;
    }

    .p_r_sum {
      @include ftz(0.875rem, 500, #423f3d);
      text-transform: capitalize;

      .p_r_sum_t {
        @include ftz(0.625rem, 500, #423f3d);
        text-transform: uppercase;
        width: 53px;
        height: 14px;
        background: #e8d36a;
        border-radius: 2px;
        margin-left: 8px;
        padding: 3px 6px;
      }
    }

    .about_p {
      @include ftz(0.75rem, 400, #666666);
      margin-bottom: 0.5rem;
      padding-top: 12px;
    }

    .btn_wholesale {
      .o_wholesale,
      .o_by_shop {
        width: 100%;
        height: 3rem;
        background: #cfebcc;
        border-radius: 2px;
        margin-top: 24px;
        margin-bottom: 7px;
        display: inline-flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        @media (max-width: 481px) {
          width: 100%;
        }
      }

      .wholesale,
      .by_shop {
        @include ftz(0.625rem, 500, #666666);
        text-transform: uppercase;
        letter-spacing: 0.13em;
        padding-top: 5px;

        @media (max-width: 668px) {
          @include ftz(0.5rem, 500, #666666);
        }
      }

      .customisation,
      .for_order {
        @include ftz(0.625rem, 500, #9cc298);
        text-transform: uppercase;
        letter-spacing: 0.13em;
        text-align: center;

        @media (max-width: 668px) {
          @include ftz(0.5rem, 500, #666666);
        }
      }

      .o_by_shop {
        border: 1px solid #c5dfc2;
        background: transparent;
        margin: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.container_order {
  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: #666666;
  }
  .range_price {
    .v-slider__thumb {
      border-radius: unset;
      width: 3px;
      left: -1.5px;
      &:before {
        content: none;
      }
    }
    .v-slider__thumb-label {
      height: 10px !important;
      width: 10px !important;
      background-color: unset !important;
      color: black;
      border-color: unset !important;
    }
  }
  .price_group {
    .v-expansion-panel-header {
      min-height: unset;
    }
    .v-expansion-panel-content__wrap {
      padding-left: 0;
    }
  }
}
</style>
